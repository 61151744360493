<template>
  <b-card-code
    title="Agregar una nueva Compañía"
  >
    <b-card-body>
      <FormCreateComponent
        v-if="accessPermissions('company.store')"
      />
    </b-card-body>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BCardBody,
} from 'bootstrap-vue'
import FormCreateComponent from './components/FormCreateComponent.vue'
import permissions from '@/libs/permissions'

export default {
  name: 'CompanyCreatePage',
  components: {
    BCardCode,
    BCardBody,
    FormCreateComponent,
  },
  methods: {
    accessPermissions(permission) {
      return permissions(permission)
    },
  },
}
</script>
